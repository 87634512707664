@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/Inter-UI-Regular.woff2") format("woff2"),
       url("../fonts/Inter-UI-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/Inter-UI-Italic.woff2") format("woff2"),
       url("../fonts/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 600;
  src: url("../fonts/Inter-UI-Medium.woff2") format("woff2"),
       url("../fonts/Inter-UI-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 600;
  src: url("../fonts/Inter-UI-MediumItalic.woff2") format("woff2"),
       url("../fonts/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 800;
  src: url("../fonts/Inter-UI-Bold.woff2") format("woff2"),
       url("../fonts/Inter-UI-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 800;
  src: url("../fonts/Inter-UI-BoldItalic.woff2") format("woff2"),
       url("../fonts/Inter-UI-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 200;
  src: url("../fonts/Inter-ExtraLight.woff2") format("woff2"),
       url("../fonts/Inter-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 200;
  src: url("../fonts/Inter-ExtraLightItalic.woff2") format("woff2"),
       url("../fonts/Inter-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 300;
  src: url("../fonts/Inter-Light.woff2") format("woff2"),
       url("../fonts/Inter-Light.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 300;
  src: url("../fonts/Inter-LightItalic.woff2") format("woff2"),
       url("../fonts/Inter-LightItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
       url("../fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/Inter-Italic.woff2") format("woff2"),
       url("../fonts/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-display: auto;
  font-weight: 700;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
       url("../fonts/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-display: auto;
  font-weight: 700;
  src: url("../fonts/Inter-BoldItalic.woff2") format("woff2"),
       url("../fonts/Inter-BoldItalic.woff") format("woff");
}