// Accent: #00bcea

html {
  box-sizing: border-box;
  line-height: 1.6;
  letter-spacing: .06em;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  // font-family: 'Inter UI', -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",  Helvetica, Arial, sans-serif;
  font-family: 'Inter', 'Inter UI', -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",  Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-display: auto;
  font-size: 2rem;
  line-height: 1.54;
  background-color: $light-background;
  color: $light-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "liga", "tnum", "case", "calt", "zero", "ss01", "locl";
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @media #{$media-size-phone} {
    font-size: 1rem;
  }

  &.dark-theme {
    background-color: $dark-background;
    color: $dark-color;
  }
}

h2, h3, h4, h5, h6 {
  display: flex;
  align-items: center;
  line-height: 1.3;
}

h1 {
  font-size: 2.625rem;
}

h2 {
  font-size: 1.625rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

@media #{$media-size-phone} {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.15rem;
  }

  h4 {
    font-size: 1.125rem;
  }
}

a {
  color: inherit;
}

#page-home a, #page-about a {
  color: #00bcea;
  text-decoration: none;
  font-weight: 400;
}

#page-home a:hover, #page-about a:hover {
  text-decoration: underline;
}

img {
  display: block;
  max-width: 100%;

  &.left {
    margin-right: auto;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
  }
  &.circle {
    border-radius: 50%;
    max-width: 25%;
    margin: auto;
  }
}

figure {
  display: table;
  max-width: 100%;
  margin: 25px 0;

  &.left {
    margin-right: auto;
  }

  &.left-floated {
    margin-right: auto;
    float: left;
    img {
      margin: 20px 20px 20px 0;
    }
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
  }

  &.right-floated {
    margin-left: auto;
    float: right;
    img {
      margin: 20px 0 20px 20px;
    }
  }

  &.rounded {
    img {
      border-radius: 50%;
    }
  }

  figcaption {
    font-size: 14px;
    margin-top: 5px;
    opacity: .8;

    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }
}

code {
  font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
  font-display: auto;
  font-feature-settings: normal;
  background: $light-background-secondary;
  padding: 1px 6px;
  margin: 0 2px;
  border-radius: 5px;
  font-size: .95rem;

  .dark-theme & {
    background: $dark-background-secondary;
  }
}

pre {
  background: #212020;
  padding: 10px 10px 10px 20px;
  border-radius: 8px;
  font-size: .95rem;
  overflow: auto;

  @media #{$media-size-phone} {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  code {
    background: none !important;
    color: #ccc;
    margin: 0;
    padding: 0;
    font-size: inherit;

    .dark-theme & {
      color: inherit;
    }
  }
}

blockquote {
  border-left: 2px solid;
  margin: 40px;
  padding: 10px 20px;

  @media #{$media-size-phone} {
    margin: 10px;
    padding: 10px;
  }

  &:before {
    content: '”';
    font-family: Georgia, serif;
    font-display: auto;
    font-size: 3.875rem;
    position: absolute;
    left: -40px;
    top: -20px;
  }

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

ul, ol {
  margin-left: 40px;
  padding: 0;

  @media #{$media-size-phone} {
    margin-left: 20px;
  }
}

ol ol {
  list-style-type: lower-alpha;
}

.container {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
  margin: 0;

  @media #{$media-size-phone} {
    margin-top: 0;
  }
}

hr {
  width: 100%;
  border: none;
  background: $light-border-color;
  height: 1px;

  .dark-theme & {
    background: $dark-border-color;
  }
}

.hidden {
  display: none;
}

.hide-on-phone {
  @media #{$media-size-phone} {
    display: none;
  }
}

.hide-on-tablet {
  @media #{$media-size-tablet} {
    display: none;
  }
}

// Accessibility
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #00bcea;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
    width: auto;
    top: 5px;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	z-index: 100000;
}

/* START: New Loading */

@-webkit-keyframes loading {
  25% {
    fill: #c6c7ca;
  }
  50% {
    fill: #049fd9;
  }
  75% {
    fill: #c6c7ca;
  }
}
@keyframes loading {
  25% {
    fill: #c6c7ca;
  }
  50% {
    fill: #049fd9;
  }
  75% {
    fill: #c6c7ca;
  }
}

.loading-logo {
  width: 72px;
  fill: #c6c7ca;
  display: inline;

  @media #{$media-size-phone} {
    width: 48px;
  }
}

.logo__mark > path {
  animation: loading 1.25s ease-in-out infinite reverse;
}

.logo__mark > path:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.logo__mark > path:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.logo__mark > path:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.logo__mark > path:nth-child(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.logo__mark > path:nth-child(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.logo__mark > path:nth-child(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.logo__mark > path:nth-child(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.logo__mark > path:nth-child(8) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.logo__mark > path:nth-child(9) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

/* END: New Loading */

#page-home p {
  text-align: justify;
}

.header a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 3px;
  display: inline-block;
  vertical-align: super;
  line-height: 0;
  transform: scale(0.8);
  .dark-theme & {
    filter: invert(1);
  }
  @media #{$media-size-phone} {
    transform: scale(0.8);
    .dark-theme & {
      filter: invert(0);
    }
}
}

#page-home a[target="_blank"]::after, #page-about a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 3px;
  display: inline-block;
  vertical-align: super;
  line-height: 0;
  transform: scale(1.2);
  .dark-theme & {
    filter: invert(1);
  }
  @media #{$media-size-phone} {
    transform: scale(0.8);
}
}

.cisco {
  color: #00bcea;
}

@media only screen and (min-width: 760px) {
  #page-home {
    width: 760px;
  }
}

@media only screen and (max-width: 759px) {
  #page-home {
    padding: 0 20px;
  }
}

.about-photo {
  text-align: center;
  margin: 0 auto;
  width: 200px;
  border-radius: 50%;
}

.about-me {
  text-align: justify;
}

.read-time {
  font-size: 1.2rem;
  @media #{$media-size-phone} {
      font-size: 0.6rem;
  }
}