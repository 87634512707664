.menu {
  // background: #fafafa;
  // border-right: 1px solid;
  font-size: 1.125rem;
  font-weight: 400;
  // font-weight: bold;
  text-transform: lowercase;
  margin-right: 18px;
  z-index: 9999;

  .dark-theme & {
    // background: #252627;
  }

  @media #{$media-size-phone} {
    // position: absolute;
    top: 50px;
    right: 0;
    border: none;
    margin: 0;
    // padding: 10px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    margin: 0 auto;
    // padding: 0 15px;
    font-size: 1rem;
    list-style: none;

    li {
      margin: 0 6px;
    }

    @media #{$media-size-phone} {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      height: 27px;

      li {
        margin: 0;
        padding: 5px;
        background: rgba(255,255,255,0.8);
        color: #333;
      }
    }
  }

  &-trigger {
    width: 24px;
    height: 24px;
    fill: currentColor;
    margin-left: 10px;
    cursor: pointer;
  }

  a {
    display: inline-block;
    margin-right: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #00bcea
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
